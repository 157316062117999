import { loadCurrentDeployData, loadCurrentPreviewData, calculateMostRecentDeploy, saveLandingPagePreviewData } from '@/views/LandingPageGenerator/listeners'

const updatePreviousStates = (previousStates, input) => {
  previousStates[0] = previousStates[1]
  previousStates[1] = input
  return previousStates
}

async function updateJobOpeningList ({ uid, previousValue, currentValue }) {
  const live = await loadCurrentDeployData(uid)
  const preview = await loadCurrentPreviewData(uid)
  const landingPageData = calculateMostRecentDeploy({ live, preview })
  if (landingPageData) {
    const { jobsData, ...rest } = landingPageData
    const updatedJobsData = jobsData.map(jobData => {
      const { jobType, ...rest } = jobData
      if (jobType === previousValue) {
        return { jobType: currentValue, ...rest }
      } else {
        return jobData
      }
    })
    return saveLandingPagePreviewData({ ownerId: uid, data: { jobsData: updatedJobsData, ...rest } })
  }
}

function listComparisonHandler ({ previousList, currentList, uid }) {
  const bothSameLengthArrays = Array.isArray(previousList) && Array.isArray(currentList) && previousList.length === currentList.length
  if (bothSameLengthArrays) {
    const diffList = previousList.map((val, index) => val === currentList[index] ? null : [val, currentList[index]])
    const firstDiff = diffList.find(i => Array.isArray(i))
    if (firstDiff) {
      const [previousValue, currentValue] = firstDiff
      return updateJobOpeningList({ previousValue, currentValue, uid })
    }
  }
}

const stateKey = 'jobOpeningTypeListJa'
export default function jobOpeningListHandler ({ uid, upil }) {
  let previousStates = [{}, {}]
  return upil.UpilStore.subscribe(() => {
    const state = upil.UpilStore.getState()
    const [previousState, newState] = updatePreviousStates(previousStates, state.input)
    const previousList = previousState[stateKey]
    const currentList = newState[stateKey]
    listComparisonHandler({ previousList, currentList, uid })
  })
}

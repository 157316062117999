/* eslint-disable camelcase */
import { firestore } from '@/helpers/firestore'
import { firebase } from '@/helpers/firebase'
import { COLLECTIONS_ENUM } from '@/enums'
import { scenarioPublicRef } from '@/listeners/recruit'
import { firestoreIncomingDataCleaner } from '@/helpers/upil'
import { parseISO, isDate, isValid } from 'date-fns'
import { getUserCompany } from '@/helpers/user-info'

export const generateLandingPageDeploysCollectionRef = ownerId => firestore
  .collection(COLLECTIONS_ENUM.LANDING_PAGE_SITES)
  .doc(ownerId)
  .collection(COLLECTIONS_ENUM.LANDING_PAGE_DEPLOYS)

export const generateLandingPagePreviewsCollectionRef = ownerId => firestore
  .collection(COLLECTIONS_ENUM.LANDING_PAGE_SITES)
  .doc(ownerId)
  .collection(COLLECTIONS_ENUM.LANDING_PAGE_PREVIEWS)

export const loadCurrentDeployData = ownerId => generateLandingPageDeploysCollectionRef(ownerId).orderBy('createdAt', 'desc').limit(1).get().then(snap => snap.docs[0]).then(landingPageDocData => landingPageDocData ? landingPageDocData.data() : null)
export const loadCurrentPreviewData = ownerId => generateLandingPagePreviewsCollectionRef(ownerId).orderBy('createdAt', 'desc').limit(1).get().then(snap => snap.docs[0]).then(landingPageDocData => landingPageDocData ? landingPageDocData.data() : null)

function handleDateBackwardsCompatibility (data) {
  const { dateOfPostData, dateOfPostData_date, ...rest } = data
  const shouldConvertOldData = !dateOfPostData_date && dateOfPostData

  if (shouldConvertOldData) {
    const convertedDate = parseISO(dateOfPostData)
    if (isDate(convertedDate) && isValid(convertedDate)) {
      return {
        dateOfPostData_date: convertedDate,
        ...rest
      }
    } else {
      return rest
    }
  } else {
    return data
  }
}

const generateJobTypePreloadedListeners = jobsData => jobType => {
  const jobDataEntry = jobsData.find(d => d.jobType === jobType) || { data: {} }
  const cleanedData = firestoreIncomingDataCleaner(jobDataEntry.data)
  const compatibleData = handleDateBackwardsCompatibility(cleanedData)
  return { 'preload-input': () => compatibleData }
}

const getMillisDeploy = deploy => deploy && deploy.createdAt ? deploy.createdAt.toMillis() : 0

export const calculateMostRecentDeploy = ({ live, preview }) => {
  if (live && preview) {
    return getMillisDeploy(live) > getMillisDeploy(preview) ? live : preview
  } else if (live && !preview) {
    return live
  } else if (preview && !live) {
    return preview
  } else {
    return null
  }
}

// Set default value for jobOpeningType1Ja
const setJobOpeningType = (jobs) => {
  return jobs.map(job => {
    job.data.jobOpeningType1Ja = job.data.jobOpeningType1Ja ? job.data.jobOpeningType1Ja : job.jobType
    return job
  })
}

export const listeners = async ({ live, preview }) => {
  const landingPageData = calculateMostRecentDeploy({ live, preview })
  if (landingPageData) {
    const { pageDetails, jobsData } = landingPageData
    const pageScriptPreloadedListeners = () => ({ 'preload-input': () => pageDetails })
    const jobTypePreloadedListeners = generateJobTypePreloadedListeners(setJobOpeningType(jobsData))
    return { pageScriptPreloadedListeners, jobTypePreloadedListeners }
  } else {
    return {
      pageScriptPreloadedListeners: () => ({ 'preload-input': () => {} }),
      jobTypePreloadedListeners: () => ({ 'preload-input': () => {} })
    }
  }
}

export const getJobTypes = ownerId => scenarioPublicRef(ownerId).get().then(doc => doc.data()).then(data => data['jobOpeningTypeListJa'])

export const saveLandingPageData = async ({ ownerId, data }) => generateLandingPageDeploysCollectionRef(ownerId).doc().set({
  ...data,
  company: await getUserCompany(ownerId),
  createdAt: firebase.firestore.Timestamp.now()
})

export const saveLandingPagePreviewData = async ({ ownerId, data }) => generateLandingPagePreviewsCollectionRef(ownerId).doc().set({
  ...data,
  company: await getUserCompany(ownerId),
  createdAt: firebase.firestore.Timestamp.now()
})

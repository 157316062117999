import { firestore } from '@/helpers/firestore'
import { COLLECTIONS_ENUM } from '@/enums'

const recruitScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID

const generateOwnerVarsRef = ownerId => firestore
  .collection(COLLECTIONS_ENUM.OWNERS)
  .doc(ownerId)
  .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
  .doc(recruitScenarioId)

function convertOldTimeToTimeRange (oldTime) {
  const [hours, minutes] = oldTime.split(':')
  return { hours: parseInt(hours), minutes: parseInt(minutes) }
}

function handleTimeRangeBackwardsCompatibility (data) {
  const returnData = {}
  const { timeRange, timeRangeStart, timeRangeEnd } = data
  const hasOldData = timeRange
  const needsToConvertStart = hasOldData && !timeRangeStart
  if (needsToConvertStart) {
    const { startTime } = timeRange
    returnData.timeRangeStart = convertOldTimeToTimeRange(startTime)
  }
  const needsToConvertEnd = hasOldData && !timeRangeEnd
  if (needsToConvertEnd) {
    const { endTime } = timeRange
    returnData.timeRangeEnd = convertOldTimeToTimeRange(endTime)
  }

  return { ...data, ...returnData }
}

export const listeners = uid => ({
  'preload-input': async () => {
    const ownerData = await generateOwnerVarsRef(uid).get().then(doc => doc.data())
    const cleanedData = handleTimeRangeBackwardsCompatibility(ownerData)
    return cleanedData
  }
})

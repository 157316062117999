<template>
  <div id="botsettings">
    <LoadingDialog v-if="!upil" />
    <BotSettings v-if="upil" :upil="upil" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { UPILCore } from '@appsocially/userpil-core'
import LoadingDialog from '@/components/LoadingDialog'
import BotSettings from '@/components/botSettings'
import recruitScript from '@/upil-scripts/recruit'
import { listeners as recruitListeners } from '@/listeners/recruit'
import { listeners as botSettingsRecruitListeners } from '@/listeners/recruit-botsettings'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'
import jobOpeningListHandler from './jobOpeningListHandler'

function setupListeners ({ listeners, upil }) {
  const unsubscribeArray = Object.keys(listeners).map(event => {
    const handler = listeners[event]
    return upil.on(event, handler)
  })

  return unsubscribeArray
}

export default {
  components: {
    LoadingDialog,
    BotSettings
  },
  data () {
    return {
      dummy: true,
      upil: null,
      unsubscribe: () => {}
    }
  },
  computed: {
    ...mapState('auth', ['uid'])
  },
  watch: {
    uid: {
      immediate: true,
      handler (uid) {
        if (uid) {
          this.start(uid)
        }
      }
    }
  },
  methods: {
    start (uid) {
      const upil = new UPILCore()

      this.unsubscribe = jobOpeningListHandler({ uid, upil })

      // Setup bot settings listeners
      const botSettingsListeners = botSettingsRecruitListeners(uid)
      setupListeners({ listeners: botSettingsListeners, upil })

      // Setup original listeners (will be called first)
      const { 'scenario-start': scenarioStart, 'scenario-end': scenarioEnd, ...desiredOriginalListeners } = recruitListeners(uid)
      setupListeners({ listeners: desiredOriginalListeners, upil })
      upil.startRaw(recruitScript, {
        mode: 'form',
        resetOnInputUpdate: true,
        dependencyResolution: 'resolve-undefined'
      })
      this.upil = upil
    }
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_BOT_SETTINGS)
  },
  destroyed () {
    this.unsubscribe()
  }
}
</script>

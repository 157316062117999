import { firebase } from '@/helpers/firebase'
import { isDate } from 'date-fns'

export const firestoreIncomingDataCleaner = data => {
  return Object.fromEntries(Object.entries(data).map(([key, value]) => {
    if (value instanceof firebase.firestore.Timestamp) {
      return [key, value.toDate()]
    } else {
      return [key, value]
    }
  }))
}

export const firestoreOutgoingDataCleaner = data => {
  return Object.fromEntries(Object.entries(data).map(([key, value]) => {
    if (isDate(value)) {
      return [key, firebase.firestore.Timestamp.fromDate(value)]
    } else {
      return [key, value]
    }
  }))
}

function componentByLabel ({ label }, component) {
  switch (label) {
    case 'appointmentDate':
      return () => import('./appointment-selector')
    case 'videoUpload':
      return () => import('./video-uploader')
    default:
      return component
  }
}

export function calculateComponent ({ type, label }, defaultComponent) {
  return componentByLabel({ label }, defaultComponent)
}

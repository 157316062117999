<template>
  <v-container id="bot-settings">
    <v-row justify="center">
      <v-col style="position: relative" class="ma-0 pa-0">
        <v-row
          :style="{
            visibility: showMissingValueMessage ? 'visible' : 'hidden',
          }"
          id="missing-info-alert"
          justify="center"
          class="ma-0 pa-0"
        >
          <v-col cols="auto">
            <v-chip label color="info">
              <v-icon left>mdi-information</v-icon>
              {{ $t("m.botSettings.missing") }}
            </v-chip>
          </v-col>
        </v-row>
        <v-card>
          <v-progress-linear v-if="initializingUpil" />
          <v-card-title>
            <v-spacer />
            {{ title }}
            <v-spacer />
          </v-card-title>
          <FormMode
            :override="calculateComponent"
            :i18n="i18n"
            :upil="upil"
            :types="types"
            @update:isMissingValue="isMissingValue = $event"
            @update:initializingUpil="initializingUpil = $event"
            locale="ja"
          />
          <slot name="actions" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FormMode } from '@appsocially/vue-upil'
import { calculateComponent } from './widget-selection'
import types from '@/helpers/types'

export default {
  components: {
    FormMode
  },
  data () {
    return {
      isMissingValue: false,
      initializingUpil: true,
      i18n: {
        ja: {
          missingValue: '未記入'
        }
      },
      calculateComponent,
      types
    }
  },
  props: {
    upil: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: function () { return this.$i18n.t('m.botSettings.title') }
    },
    suppressMissingValueMessage: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showMissingValueMessage () {
      return this.isMissingValue && !this.suppressMissingValueMessage
    }
  },
  mounted () {
    window.DumpUpil = () => {
      if (this && this.upil) {
        const dump = {
          script: this.upil.script,
          state: this.upil.UpilStore.getState().input
        }

        console.log('Dumping upil state...') // eslint-disable-line no-console
        return dump
      } else {
        console.log('no upil detected') // eslint-disable-line no-console
      }
    }
  },
  destroyed () {
    delete window.DumpUpil
  }
}
</script>

<style scoped>
#bot-settings >>> .upil-dynamic-list {
  margin-top: 5px;
}

#bot-settings >>> .upil-dynamic-list .v-input__slot {
  background-color: var(--v-secondary-darken1) !important;
}

#bot-settings #missing-info-alert {
  z-index: 100;
  position: sticky;
  width: 100%;
  right: 0;
  top: 50px;
}
</style>
